'use client';
import Loading from '@/components/atoms/loading';
import dynamic from 'next/dynamic';

export const LoginCompo = dynamic(() => import('./login'), {
    // loading: () => <Loading />,
    ssr: false
});
export const PhoneEmailVerify = dynamic(() => import('./phone-email-verify'), {
    ssr: false
});
